import React, { useEffect } from "react";
import Image from "next/image";
import NextLink from "next/link";
import { Link } from "@chakra-ui/react";

import LinkComponent from "src/components/atoms/Link";

import { PostParsed } from "src/services/contentful/post/postParser";
import {
  Wrapper,
  CoverImageWrapper,
  Body,
  FeaturedLabel,
  TitleWrapper,
  Title,
  PostDate,
  Excerpt,
  ReadMoreButton,
} from "./styles";

const postDateTemplate = {
  year: "numeric",
  month: "long",
  day: "numeric",
} as const;

export type FeaturedPostCardProps = {
  post: PostParsed;
};
const FeaturedPostCard: React.FC<FeaturedPostCardProps> = ({
  post,
  ...rest
}) => {
  const [pst, setPst] = React.useState<PostParsed>(post);
  useEffect(() => {
    setPst(post);
  }, [post]);
  return (
    <Wrapper {...rest}>
      {pst?.coverImage && pst?.coverImage?.url ? (
        <CoverImageWrapper data-testid="CoverImageWrapper">
          <Image
            src={pst?.coverImage?.url?.toString()}
            alt={pst?.coverImage?.fileName?.toString() || ""}
            layout="fill"
            objectFit="cover"
          />
        </CoverImageWrapper>
      ) : null}
      <Body>
        <FeaturedLabel>Featured</FeaturedLabel>
        <TitleWrapper>
          <LinkComponent href={`/blog/${pst?.slug}`} component={Title}>
            {pst?.title}
          </LinkComponent>
        </TitleWrapper>
        <PostDate dateTime={pst?.date}>
          {new Date(pst?.date).toLocaleDateString("en-US", postDateTemplate)}
        </PostDate>
        <Excerpt>{pst?.excerpt}</Excerpt>

        <NextLink href={`/blog/${pst?.slug}`} passHref legacyBehavior>
          <Link
            aria-label={`Read more on ${pst?.title}`}
            variant="brand-solid-accent"
          >
            Read more
          </Link>
        </NextLink>
      </Body>
    </Wrapper>
  );
};

export default FeaturedPostCard;
