import React from "react";
import FeaturedPostCard from "./FeaturedPostCard";
import FeaturedPostCardOld from "./index.old";

const FeaturedPostCardSwitcher = (props: any) => {
  console.log("FeaturedPostCardSwitcher", props);
  return props.featuredBlogPost ? (
    <FeaturedPostCard
      internalName={props.internalName}
      featuredBlogPost={props.featuredBlogPost}
      key={"FeaturedPost"}
    />
  ) : (
    <FeaturedPostCardOld post={props.post} key={"FeaturedPost"} />
  );
};

export default FeaturedPostCardSwitcher;
