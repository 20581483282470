import styled from "styled-components";
import { down } from "styled-breakpoints";
import Typography from "src/components/atoms/Typography";
import Button from "src/components/atoms/Button";

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 20px;
  box-shadow: 0 0 25px -3px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  min-height: 466px;
  display: flex;
  align-items: stretch;

  ${down("md")} {
    display: block;
    min-height: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const CoverImageWrapper = styled.div`
  width: 50%;
  max-width: 560px;
  flex: none;
  position: relative;
  overflow: hidden;

  ${down("md")} {
    height: 194px;
    max-width: none;
    width: 100%;
    border-radius: 10px;
  }
`;

export const Body = styled.div`
  padding: 74px 20px 60px 60px;
  text-align: left;

  ${down("md")} {
    padding: 61px 16px 43px;
  }
`;

export const FeaturedLabel = styled(Typography)`
  display: inline-block;
  font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};
  font-size: 14px;
  line-height: 1;
  letter-spacing: 1.14px;
  color: ${({ theme }) => theme.palette.primary.main};
  text-transform: uppercase;
`;

export const TitleWrapper = styled.div`
  margin-top: 34px;

  ${down("md")} {
    margin-top: 10px;
  }
`;

export const Title = styled(Typography).attrs({
  variant: "h3",
  component: "a",
})`
  font-size: 45.6px;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  ${down("md")} {
    font-size: 30px;
  }
`;

export const PostDate = styled.time`
  display: inline-block;
  margin-top: 12px;

  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  line-height: 16px;

  ${down("md")} {
    margin-top: 8px;
    font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};
    font-weight: bold;
    font-style: normal;
  }
`;

export const Excerpt = styled.div`
  margin-top: 17px;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;

  ${down("md")} {
    margin-top: 10px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ReadMoreButton = styled(Button).attrs({ as: "a", size: "sm" })`
  margin-top: 35px;

  ${down("md")} {
    margin-top: 30px;
    font-size: 14px;
    width: 100%;
    padding: 0.8em;
  }
`;
