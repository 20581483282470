import React from "react";
import Image from "next/image";
import NextLink from "next/link";
import { Heading, Link, Text } from "@chakra-ui/react";

import { Wrapper, CoverImageWrapper, Body } from "./styles";
import {
  IFeaturedBlogPostFields,
  IPage,
  IPageBlogPost,
  IPageBlogPostFields,
  IPageFields,
  ISeoFields,
} from "src/types/generated/contentful";
import { fixImagePath } from "src/utils/dom";
import { AssetFields } from "contentful";

export const getExcerptOrSeoDescription = (
  post: IPageBlogPostFields,
  seo: ISeoFields
): string => {
  let seoDescription = post?.excerpt || seo?.description;
  if (
    seoDescription &&
    seoDescription.length > 0 &&
    seoDescription.indexOf("*** DEPRECATED ***") > -1
  ) {
    // fix issue where *** DEPRECATED *** showed up in the excerpt on accident for shd
    seoDescription = seo?.description;
  }
  return seoDescription || "";
};
const postDateTemplate = {
  year: "numeric",
  month: "long",
  day: "numeric",
} as const;

const FeaturedPostCard: React.FC<IFeaturedBlogPostFields> = (data) => {
  //  console.log("FeaturedPostCard", JSON.stringify(data));
  const page = data?.featuredBlogPost?.fields as IPageFields;
  const post = page?.content?.fields as IPageBlogPostFields;
  const image = post?.coverImage?.fields as AssetFields;
  const seo = page?.seo?.fields as ISeoFields;

  return (
    <Wrapper {...data}>
      {post?.coverImage?.fields?.file?.url && (
        <CoverImageWrapper data-testid="CoverImageWrapper">
          <Image
            src={fixImagePath(image?.file?.url)}
            alt={image?.description || ""}
            fill={true}
            style={{ objectFit: "cover" }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
        </CoverImageWrapper>
      )}
      <Body>
        {/* export const FeaturedLabel = styled(Typography)`
  display: inline-block;
  font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};
  font-size: 14px;
  line-height: 1;
  letter-spacing: 1.14px;
  color: ${({ theme }) => theme.palette.primary.main};
  text-transform: uppercase;
`;
 */}
        <Text
          fontSize="sm"
          textTransform="uppercase"
          fontWeight="semibold"
          color="brandAccent.500"
        >
          Featured
        </Text>

        {/* title */}
        <Heading as="h3" size={["xl", "xl", "2xl"]} lineHeight="85%">
          <NextLink href={`/blog/${page?.slug}`} passHref legacyBehavior>
            <Link
              aria-label={`Read more on ${page?.title}`}
              color="brandPrimary.500"
            >
              {page?.title}
            </Link>
          </NextLink>
        </Heading>

        {/* date */}
        <Text
          fontSize={["xs", "xs", "sm"]}
          fontWeight={"bold"}
          fontStyle={"italic"}
        >
          {page?.pubDate
            ? new Date(page?.pubDate).toLocaleDateString(
                "en-US",
                postDateTemplate
              )
            : null}
        </Text>

        {/* excerpt */}
        <Text
          mt={[4, 4, 6]}
          mb={8}
          fontSize={["lg", "lg", "xl"]}
          lineHeight={[6, 6, 8]}
        >
          {getExcerptOrSeoDescription(post, seo)}
        </Text>

        {/* read more button */}
        <NextLink href={`/blog/${page?.slug}`} passHref legacyBehavior>
          <Link
            aria-label={`Read more on ${page?.title}`}
            variant="brand-solid-accent"
          >
            Read more
          </Link>
        </NextLink>
      </Body>
    </Wrapper>
  );
};

export default FeaturedPostCard;
